/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import {
  Box,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import config from 'config';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { AiFillCloseCircle } from 'react-icons/ai';
import { TbSearch } from 'react-icons/tb';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextContent from '@lb/Common/StructuredTextContent';

/* Services */
/* Styles */
import styles from '@lb/components/Banners/TopBanner.module.css';
import LeftQuote from '@lb/components/Icons/LeftQuote';
import RightQuote from '@lb/components/Icons/RightQuote';

/**
 * Renders Top Banner Dark Component
 *
 * @param {object} sectionContent
 * @param  region
 * @param language
 * @returns
 */

const TopBannerDark = ({ sectionContent, region, language, filter }) => {
  const router = useRouter();
  const inputRef = useRef();
  const noQuote = router.asPath.toString().search(/events|ritual/);
  const mobBg = sectionContent?.mobileImage?.url;
  const desktopBg = sectionContent?.desktopImage?.url;
  const [value, setValue] = useState('');
  //console.log(value.length);
  useEffect(() => {
    if (sectionContent?.animation) {
      gsap.set('.text', { opacity: 1 });
      gsap
        .timeline({
          scrollTrigger: {
            trigger: '.text-container',
            start: '0 top',
            end: '+=300px',
            scrub: 0.6,
            pinSpacing: true,
            pin: true,
            markers: false,
            toggleActions: 'play none none none',
          },
        })
        .to('.text:nth-child(1)', { opacity: 1, y: 0, duration: 3 })
        .to('.text:nth-child(1)', { opacity: 0, y: 0, display: 'none' })
        .to('.text:nth-child(2)', { opacity: 1, y: 0, display: 'block' })
        .to('.text:nth-child(2)', { opacity: 1, y: 0 });
    }
  }, []);

  useEffect(() => {
    // console.log('router.query.search,', inputRef.current.value, router.query.search);
    if (router?.query?.search && inputRef?.current) {
      inputRef.current.value = router.query.search;
      // console.log('router.query.search111111111111111,', inputRef.current.value, router.query.search);
    }
  }, [router.query.search]);

  // if (sectionContent.animation) {
  gsap.registerPlugin(ScrollTrigger);
  // }
  return (
    <Box className={sectionContent?.animation ? 'text-container' : ''}>
      <Box
        width="100%"
        bgColor="common.white"
        pos={filter ? 'relative' : 'static'}
      >
        <Box
          mx="auto"
          maxW={1330}
          w="100%"
          // pos={filter.searchFilterVisibility ? 'relative' : 'static'}
          pos="relative"
        >
          <Text
            fontSize={{ base: '32px', md: 'h4' }}
            as="h1"
            textTransform="uppercase"
            fontFamily="FedraSerifDisplay"
            fontStyle="normal"
            fontWeight="600"
            lineHeight="100%"
            margin="0"
            //marginBottom={{ base: '-13px', md: '-16px' }}
            pl={{ base: 8, lg: 24 }}
            position="relative"
            top={{ base: '5px', md: '8px' }}
            color="primary.main"
          >
            {sectionContent?.title}
          </Text>
          <Box
            // pl={{ base: 8, lg: 24 }}
            bottom={{ base: '-27px', md: '-40px' }}
            left={{ base: 8, md: 8, lg: 24 }}
            top={{ base: '35px', sm: '35px', md: '45px' }}
            pos="absolute"
          >
            <Text
              fontSize={{ base: 'body3', md: 'body1' }}
              fontFamily="FedraSansStd-Book"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="24px"
              fontVariant="all-small-caps"
              style={{ fontVariant: 'all-small-caps' }}
              letterSpacing="0.37em"
              color="#8D7452"
              pos="relative"
              zIndex="2"
              as="h2"
              textTransform="uppercase"
              // mt="6px"
            >
              {sectionContent?.titleSubtext}
            </Text>
            {sectionContent?.titleSubtext && (
              <Box
                pos="absolute"
                data="data-tooltip"
                width={{ base: '74px', md: '133px' }}
                height={{ base: '37px', md: '67px' }}
                maxW="133px"
                top={{ base: '-9px', md: '-7px' }}
                left={{ base: '-37px', md: '-62px' }}
                maxH="67px"
                zIndex="1"
              >
                <LazyLoadImageComponent
                  w="100%"
                  h="100%"
                  objectFit="contain"
                  src={`${config.imgPath}/d/46272/1683176128-image-8.png`}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {sectionContent?.description && (
        <Box
          mx="auto"
          maxW={1330}
          w="100%"
          pos={filter ? 'relative' : 'static'}
        >
          <Box
            bgImg={{
              base: `linear-gradient(180deg, #340209 10.01%, rgba(72, 0, 10, 0) 69.79%), url(${
                mobBg || desktopBg
              })`,
              md: `linear-gradient(95.18deg, #340209 29.01%, rgba(72, 0, 10, 0) 69.79%), url(${desktopBg})`,
            }}
            backgroundRepeat="no-repeat"
            bgSize="cover"
            bgColor="#340209"
            bgPos={{ base: 'center 100px', md: 'right top' }}
            objectFit="cover"
            w="100%"
            h={{ base: '470px', md: '416px' }}
            display="flex"
            alignItems={{ base: 'baseline', md: 'center' }}
          >
            <Flex
              // h={{ base: 'auto', md: '100%' }}
              width={{ base: 'auto', md: '50%' }}
              justifyContent="center"
              alignItems="center"
              flexDir="column"
              top="0"
              boxSizing=" content-box"
              right="0"
              border="none"
              minH="100px"
              pl={{ base: '19px', md: '0' }}
              pr={0}
              pt={{
                base: sectionContent?.titleSubtext ? '30px' : '22px',
                sm: '52px',
                md: '0px',
              }}
              className={styles.DarkBannerAfter}
              left={0}
            >
              <Box
                justifyContent="center"
                display="flex"
                flexDir="column"
                alignSelf="center"
                fontFamily="FedraSerifAStdBook"
                fontStyle="normal"
                fontWeight="350"
                fontSize={{ base: 'body2', md: 'body1' }}
                lineHeight={{ base: '160%', md: '164%' }}
                color="bodyText.color3"
                maxW={{ base: '90%', md: '480px' }}
                width={{ base: '88%', md: '100%' }}
              >
                <Box
                  position="relative"
                  zIndex={2}
                  px={{ base: 0, md: 12, lg: 0 }}
                  opacity={sectionContent?.animation ? ' ' : '1'}
                  className={sectionContent?.animation ? 'text' : ''}
                >
                  {sectionContent?.description && (
                    <Text
                      as="span"
                      maxW="20px"
                      width="100%"
                      className="lb-icons"
                      pos="relative"
                      display={noQuote > 1 ? 'none' : 'inline'}
                    >
                      <LeftQuote />
                      <style>
                        {`
                    .lb-icons svg{
                      display:inline !important;
                    }
                      `}
                      </style>
                    </Text>
                  )}
                  <Box
                    display="inline"
                    fontFamily="FedraSerifAStdBook"
                    fontSize={{ base: 'body1', md: '20px' }}
                    color="bodyText.color3"
                    lineHeight={{ base: '25.6px', md: '32.8px' }}
                    className={styles.BannerDark}
                  >
                    {StructuredTextContent(
                      sectionContent?.description,
                      region,
                      language
                    )}
                  </Box>
                  {sectionContent?.description && (
                    <Text
                      as="span"
                      maxW="20px"
                      width="20px"
                      display={noQuote > 1 ? 'none' : 'inline'}
                      className="lb-icons"
                      position="relative"
                      mb="10px"
                    >
                      <RightQuote />
                    </Text>
                  )}
                  {
                    <Text
                      width="100%"
                      fontSize={{ base: 'body1', md: '20px' }}
                      lineHeight={{ base: '29.52px', md: '32.8px' }}
                      fontWeight="600"
                      letterSpacing="1.5px"
                      color="bodyText.color3"
                      mt={{ base: '0', md: '12px' }}
                      fontFamily="'FedraSerifAStdBook'"
                    >
                      {StructuredTextContent(
                        sectionContent?.quoteName,
                        region,
                        language
                      )}
                    </Text>
                  }
                </Box>

                {/*2nd text*/}
                <Box
                  position="relative"
                  zIndex={2}
                  px={{ base: 0, md: 12, lg: 0 }}
                  display="none"
                  className={sectionContent?.animation ? 'text' : ''}
                >
                  {sectionContent?.animationStep2Text && (
                    <Text
                      as="span"
                      maxW="20px"
                      width="100%"
                      className="lb-icons"
                      pos="relative"
                      display={noQuote > 1 ? 'none' : 'inline'}
                    >
                      <style>
                        {`
                    .lb-icons svg{
                      display:inline !important;
                    }
                      `}
                      </style>
                    </Text>
                  )}
                  <Box
                    display="inline"
                    fontFamily="FedraSerifAStdBook"
                    fontSize={{ base: 'body1', md: '20px' }}
                    color="bodyText.color3"
                    lineHeight={{ base: '25.6px', md: '32.8px' }}
                    className={styles.BannerDark}
                  >
                    {StructuredTextContent(
                      sectionContent?.animationStep2Text,
                      region,
                      language
                    )}
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Box>

          {filter && (
            <Flex
              alignItems="center"
              w="100%"
              height="auto"
              justifyContent="flex-end"
              gridGap="15px"
              flexWrap="wrap"
              position="absolute"
              px={{ base: '16px', md: '0' }}
              zIndex="99"
              mt={{ base: '-23px' }}
              top={{ base: 'unset', md: '5px', lg: '5px' }}
              right={{ base: 0, md: '30px', lg: '60px' }}
            >
              {filter?.searchFilterVisibility && (
                <FormControl
                  border="1px solid #EDD1C2"
                  rounded="full"
                  w={{ base: '100%', sm: '343px', md: '522px' }}
                  bg="#FFF"
                  px="20px"
                >
                  <InputGroup
                    color="#B7987E"
                    w="100%"
                    alignItems="center"
                    variant="outline"
                  >
                    <InputLeftElement
                      mt="3px"
                      cursor="pointer"
                      color="#B7987E"
                      ml={{ base: '0', md: '10px' }}
                      fontSize="1.2em"
                    >
                      <TbSearch />
                    </InputLeftElement>
                    <Input
                      // value={router.query.search}
                      id="search"
                      type="text"
                      style={{ searchDecoration: 'none' }}
                      autoComplete="off"
                      placeholder={
                        filter?.blogFilterPlaceholderText?.json
                          ?.searchPlaceholder
                      }
                      color="#B7987E"
                      ml={{ base: '10px', md: '18px' }}
                      height="45px"
                      fontFamily="FedraSerifAStdBook"
                      _placeholder={{
                        color: '#B7987E',
                        fontWeight: '350',
                        fontSize: '18px',
                        lineHeight: '22px',
                      }}
                      w="100%"
                      variant="unstyled"
                      ref={inputRef}
                      onChange={e => setValue(e.currentTarget.value)}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          router.push({
                            pathname: router.asPath.split('?')[0],
                            query: {
                              search: value,
                            },
                          });
                        }
                      }}
                    />
                    {value?.length > 0 && (
                      <InputRightElement
                        onClick={() => (inputRef.current.value = '')}
                      >
                        <AiFillCloseCircle color="green.500" />
                      </InputRightElement>
                    )}
                  </InputGroup>
                </FormControl>
              )}
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TopBannerDark;
