/* Built In Imports */
import React from 'react';
/* External Imports */
/* Internal Imports */
/* Components */
/* Styles */
/* Services */
/**
 * Render LeftQuote Icon
 * @returns 
 */
const LeftQuote = () => {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        // display: 'inline !important',
        width: '20px',
        position: 'absolute',
        top: '-4px',
        left: -24,
      }}
      //left quote
    >
      <path
        d="M19.5513 1.45209L17.8918 0C14.7802 1.86697 11.4093 6.58625 11.4093 10.4239C11.4093 11.7204 14.4172 14.832 15.4544 14.832C16.1804 14.832 19.3957 11.7723 19.3957 10.8907C19.3957 10.2165 17.1139 7.8309 15.973 7.10485C16.0248 4.823 18.2548 2.85232 19.5513 1.45209ZM8.14206 1.45209L6.48253 0C3.37092 1.86697 0 6.58625 0 10.4239C0 11.7204 3.0079 14.832 4.0451 14.832C4.77114 14.832 7.98648 11.7723 7.98648 10.8907C7.98648 10.2165 5.70463 7.8309 4.5637 7.10485C4.61556 4.823 6.84555 2.85232 8.14206 1.45209Z"
        fill="#F6A15E"
      />
    </svg>
  );
};

export default LeftQuote;
