/* Built In Imports */
import NextLink from 'next/link';
import React from 'react';

/* External Imports */
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { CgNotes } from 'react-icons/cg';

/* Internal Imports */
import { refineUrlDomain } from '@lb/components/Utility/utils';
/* Components */
// import ShareIcon from '@lb/components/Banners/ShareIcon';

/* Styles */
import styles from '@lb/components/Blogs/Post.module.css';

/* Services */
/* Shared files */
import months from '@lb/components/SharedFiles/months';

/**
 *
 * @param {object} post
 * @returns
 */

const PostWImage = ({ post, region, lang }) => {
  let icon = {
    article: <CgNotes />,
    video: <AiOutlinePlayCircle />,
  };

  let date = new Date(post?.createdAt);

  return <>
    <Box
      maxW={{ base: '100%', sm: 'auto', md: '590px' }}
      height={{ base: '187px', md: '203px' }}
      padding={{ base: '30px 20px', md: '34px 32px' }}
      background="#ffffff"
      pos="relative"
      w="100%"
      zIndex={2}
    >
      <NextLink
        href={refineUrlDomain(post?.url || post?.urlAlias, region, lang)}
        passHref
        legacyBehavior>
        <Link
          position="relative"
          zIndex="1"
          cursor={post?.url || post?.urlAlias ? 'cursor' : 'auto'}
          _hover={{ textDecoration: 'none' }}
        >
          <>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              //   mt={{ base: '18px', md: '20px' }}
            >
              <Text
                color="bodyText.color2"
                fontSize={{ base: '12.63px', md: 'body3' }}
                lineHeight={{ base: '23.46px', md: '26px' }}
                style={{ fontFeatureSettings: 'case on' }}
                fontStyle="normal"
                fontFamily="FedraSansStd-Book"
                fontWeight="400"
              >
                {date.getDate() < 10 && '0'}
                {date?.getDate()}&nbsp;
                {months[date.getMonth()]} {date.getFullYear()}&nbsp;
                {post?.readingTime ? ' | ' : ''}
                {post?.readingTime
                  ?.replace('~', '')
                  ?.replace('~', '')
                  ?.replace('0 seconds', ' < 1 minute')
                  ?.replace('seconds', 'sec')}
                {post?.duration &&
                  `| ${Number(post?.duration.slice(0, 2))} min`}
              </Text>
              <Text
                color="buttonText.color1"
                fontSize={{ base: '20px', md: '25px' }}
                fontWeight="bold"
              >
                <Box className="label">
                  {post?.contentType == 'video' ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      gridGap="7px"
                      w="auto"
                      height="auto"
                      maxH="26px"
                      p="6px"
                      bg="#DB3030"
                      borderRadius="29px"
                      color="#ffffff"
                      justifyContent="center"
                    >
                      <Text
                        as="span"
                        fontSize="12px"
                        lineHeight="16px"
                        fontFamily="FedraSansStd-Book"
                        fontStyle="normal"
                        fontWeight="500"
                      >
                        <AiOutlinePlayCircle />
                      </Text>
                      <Text
                        as="span"
                        fontSize="12px"
                        lineHeight="16px"
                        fontFamily="FedraSansStd-Book"
                        fontStyle="normal"
                        fontWeight="500"
                      >
                        Video
                      </Text>
                    </Box>
                  ) : post?.contentType == 'article' ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      gridGap="7px"
                      w="auto"
                      height="auto"
                      maxH="26px"
                      p="6px"
                      bg="#DB3030"
                      borderRadius="29px"
                      color="#ffffff"
                      justifyContent="center"
                    >
                      <Text
                        fontSize="12px"
                        lineHeight="16px"
                        fontFamily="FedraSansStd-Book"
                        fontStyle="normal"
                        fontWeight="500"
                      >
                        <CgNotes />
                      </Text>
                      <Text
                        fontSize="12px"
                        lineHeight="16px"
                        fontFamily="FedraSansStd-Book"
                        fontStyle="normal"
                        fontWeight="500"
                      >
                        Article
                      </Text>
                    </Box>
                  ) : null}
                </Box>
                {/* <ShareIcon background="transparent" /> */}
              </Text>
            </Flex>

            <Box
              fontSize={{ base: '20px', md: '26px' }}
              lineHeight={{ base: '25px', md: '30px' }}
              fontWeight="350"
              fontFamily="'FedraSerifAStdBook', system-ui, sans-serif"
              fontStyle="normal"
              maxH={{ md: '90px', base: '75px' }}
              overflow="hidden"
              mt={{ base: '25px', md: '18px' }}
              textDecor="none"
              color="#121212"
              className={styles.postWMemory}
              title={post?.title}
              as="h4"
            >
              {post?.title}
            </Box>
          </>
        </Link>
      </NextLink>
    </Box>
  </>;
};

export default PostWImage;
