/* Built In Imports */
import React from 'react';
/* External Imports */
/* Internal Imports */
/* Components */
/* Styles */
/* Services */

/**
 * Renders RightQuote Icon
 *
 * @returns RightQuote Icon
 */
const RightQuote = () => {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        //display: 'inline !important',
        position: 'absolute',
        bottom: '7px',
        top: 0,
        right: -24,
      }}
    >
      <path
        d="M-4.78107e-05 13.3799L1.65948 14.832C4.77109 12.9651 8.14201 8.24578 8.14201 4.40812C8.14201 3.11162 5.13412 1.26039e-06 4.09691 1.35106e-06C3.37087 1.41454e-06 0.155532 3.05976 0.155532 3.94138C0.155532 4.61557 2.43738 7.00114 3.57831 7.72718C3.52645 10.009 1.29646 11.9797 -4.78107e-05 13.3799ZM11.4092 13.3799L13.0687 14.832C16.1804 12.9651 19.5513 8.24578 19.5513 4.40812C19.5513 3.11161 16.5434 2.62959e-07 15.5062 3.53634e-07C14.7801 4.17107e-07 11.5648 3.05976 11.5648 3.94138C11.5648 4.61556 13.8466 7.00113 14.9876 7.72718C14.9357 10.009 12.7057 11.9797 11.4092 13.3799Z"
        fill="#F6A15E"
      />
    </svg>
  );
};

export default RightQuote;
