/* Built In Imports */
/* External Imports */
import forEach from 'lodash/forEach';
/* Internal Imports */
/* Components */
import config from '@config';
/* Styles */
/* Services */

export const consoleLog = (msg, type = 'log') => {
  if (config.IS_LOG) {
    switch (type) {
      case 'log':
        console.log(msg);
        break;
      case 'info':
        console.info(msg);
        break;
      case 'warn':
        console.warn(msg);
        break;
      case 'error':
        console.error(msg);
        break;
      default:
        console.log(msg);
    }
  }
};

export const consoleGroup = (heading, msgArray) => {
  if (config.IS_LOG) {
    console.group(heading);
    forEach(msgArray, function (msg) {
      console.log(msg);
    });
    console.groupEnd();
  }
};

let uniqueLanguage = [];
config.msrlanguage?.map(locale => {
  uniqueLanguage?.push(locale);
});
export const getLocale = (region, language, site) => {
  let setregion = '';
  if (site === 'msr') {
    uniqueLanguage.find(itemlist => {
      if (itemlist.language_sf === language) {
        setregion = itemlist.region_sf;
      }
    });
    return setregion === '' ? language : `${language}-${setregion}`;
  } else {
    return region === 'global'
      ? language
      : `${language}-${region?.toUpperCase()}`;
  }
};
