/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import BlogCard from '@lb/components/Blogs/BlogCard';

/* Services */
import { fetchBlogData } from '@services/lbService';

/**
 *
 * @param {object} sectionContent
 * @returns
 */

const LingaBhairaviFacadeBlogListingPlaceholder = ({
  sectionContent,
  region,
  lang,
  tUuid,
  loadedPosts
}) => {
  const router = useRouter();
  // if (sectionContent.contentType === 'linga_bhairavi_blog_topic') {
    // console.log('sectionContent', sectionContent);
  // }
  let postList = [];
  // console.log('router?.query', router?.query, router?.query.sort);
  if (router?.query?.topic || router?.query?.search || router?.query?.sort) {
    postList = [];
  } else if (sectionContent?.cards?.length) {
    postList = sectionContent?.cards;
  } else if (loadedPosts?.length) {
    postList = loadedPosts;
  }
  
  // console.log('postList', postList);
  const [posts, setPosts] = useState(postList);
  const [isLoader, setIsLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(postList?.length);
  const [hasLoadMore, setHasLoadMore] = useState(
    postList?.length > 11 ? true : false
  );
  const limit = 12;

  const bgBlock = {
    linga_bhairavi_blog_video: '1681104457-1679922897-img_1491-3.png',
    linga_bhairavi_blog_article: '1681104457-1679922897-img_1491-3.png',
  };

  const contentType = {
    linga_bhairavi_blog_video: 'video',
    linga_bhairavi_blog_article: 'article',
    linga_bhairavi_blog: '',
  };

  const loadMorePosts = async () => {
    // console.log('loadMorePosts', router.query, posts.length, hasLoadMore);
    // if (hasLoadMore || sectionContent.showLoadMoreButton) {

    // if (!postList.length) {
      setIsLoader(true);
      let morePosts = await fetchBlogData(
        contentType[sectionContent?.contentType],
        router.query.sort || 'newest',
        posts?.length,
        limit,
        tUuid || (router.asPath.indexOf('/topic/') < 0 ? router.query.topic : ''),
        router.query.search
      );
      if (morePosts?.posts?.cards?.length > 0) {
        setPosts(prev => [...prev, ...morePosts?.posts?.cards]);
        setTotalCount(morePosts?.posts?.totalCount);
        setHasLoadMore(posts.length + limit < morePosts?.posts?.totalCount);
      }
      postList = [];
      setIsLoader(false);
    // }
    //}
  };

  // useEffect(
  //   () => () => {
  //     if (posts?.length) {
  //       // console.log('clear useEffect22', posts.length, hasLoadMore, router.asPath);
  //       setPosts([]);
  //       setTotalCount(0);
  //       setHasLoadMore(false);
  //     }
  //   },
  //   [router.asPath]
  // );

  useEffect(() => {
    // console.log('called useEffect', postList.length, router.isReady);
    if (router.isReady && !postList.length) {
      // console.log('isReady useEffect', router.isReady, posts.length, hasLoadMore, router.asPath);
      setPosts([]);
      setTotalCount(0);
      setHasLoadMore(false);
      // console.log('useEffect', posts.length, hasLoadMore, router.asPath);
      loadMorePosts();
    }  
  }, [router.isReady, router.asPath]);

  return (
    <>
      <Box w="100%" maxW={1330} mx="auto" px={{ base: '17px', md: '60px' }}>
        <Flex textAlign="left">
          <Text
            fontSize={{ base: '26px', md: '32px' }}
            lineHeight={{ base: '34px', md: '42px' }}
            fontFamily="'FedraSerifAStdBook', system-ui, sans-serif"
            fontWeight="bold"
            fontStyle="normal"
            color="headingText.main"
          >
            {sectionContent?.title}{' '}
            {router.query.search ? `(${posts.length})` : ``}
          </Text>
        </Flex>
      </Box>

      <Box
        display="grid"
        alignContent="center"
        w="100%"
        pos="relative"
        maxW={1330}
        m="0 auto 40px"
      >
        {bgBlock[sectionContent?.contentType] && (
          <Box
            position="absolute"
            top={
              sectionContent.contentType === 'linga_bhairavi_blog_article' ||
                sectionContent.contentType === 'linga_bhairavi_blog_video'
                ? '-140px'
                : '-220px'
            }
            left="35px"
            width={{ base: '0', md: '0', xl: '204px' }}
            height={{ base: '0', md: '0', xl: '200px' }}
            zIndex="10"
            userSelect="none"
          >
            <LazyLoadImageComponent
              src={`${config.imgPath}/d/46272/${bgBlock[sectionContent?.contentType]
                }`}
              objectFit="contain"
              width="100%"
              height="100%"
              userSelect="none"
              draggable="false"
              style={{
                userSelect: 'none',
                draggable: 'false',
                pointerEvents: 'none',
              }}
            />
          </Box>
        )}
        
        <BlogCard post={posts} region={region} lang={lang} loading={isLoader} />
        
        {posts?.length > 0 && sectionContent?.showLoadMoreButton && hasLoadMore && (
          <Box
            display="flex"
            alignContent="center"
            w="100%"
            pos="relative"
            maxW={1330}
            mx="auto"
          >
            <Box
              m="20px auto 0 auto"
              bottom="0"
              right="0"
              variant="primary"
              textDecoration="none"
              _hover={{ textDecoration: 'none' }}
              _focus={{ textDecoration: 'none' }}
              _active={{ textDecoration: 'none' }}
              mb="50px"
            >
              <Button
                variant="primary"
                _hover={{ textDecoration: 'none' }}
                _focus={{ textDecoration: 'none' }}
                _active={{ textDecoration: 'none' }}
                size="lg"
                fontWeight="300"
                onClick={() => loadMorePosts()}
              >
                {(sectionContent?.loadMore &&
                  sectionContent?.loadMore[0]?.buttonText) ||
                  'Load More'}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default LingaBhairaviFacadeBlogListingPlaceholder;
