/* Built In Imports */
/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import Post from '@lb/components/Blogs/Post';
import PostWImage from '@lb/components/Blogs/PostWImage';

/* Services */

/**
 *
 * @param {object} blogs
 * @returns
 */

const BlogCard = ({
  sectionContent,
  post,
  tab,
  region,
  lang,
  blogCardStyle,
  blockStyle,
  bgSize,
  loading = false,
}) => {
  const bgBlock = {
    'tree-decoration': '1681104457-1679922897-img_1491-3.png',
    'triangle-decoration': '1682764652-1681109264-mask-group.png',
  };

  return (
    <Box maxW={1330} mx="auto" w="100%">
      <Box pos="relative" pt="60px" pb="0">
        <Box
          position="absolute"
          top="0"
          left={0}
          right={0}
          height="100%"
          background={
            'linear-gradient(180deg, #D4C3A2 0%, rgba(217, 217, 217, 0) 60.56%)'
          }
          bgSize={
            bgSize || {
              base: '100% 375px !important',
              md: '100% 451px !important',
            }
          }
          bgRepeat="no-repeat !important"
          zIndex={0}
        ></Box>
        <Flex
          gridGap={{
            base: '20px',
            md: blogCardStyle === 'without_image' ? '30px' : '38px',
          }}
          flexWrap="wrap"
          flexDir="row"
          w="auto"
          mx="auto"
          maxW={1210}
          pl={{ base: '16px', md: '0' }}
          pr={{ base: '18px', md: '0' }}
          alignItems="flex-start"
          _after={{
            content: "''",
            w: '100%',
            minW: '1px',
            maxW: '590px',
            height: '10px',
          }}
          justifyContent="center"
          pos="relative"
          // mx="auto"
        >
          {bgBlock[blockStyle] && blockStyle !== 'default' && (
            <Box
              position="absolute"
              top={{
                base: '-265px',
                sm: '-265px',
                md: '-323px',
                lg: '-323px',
                xl: '-235px',
              }}
              left={{
                base: '8px',
                sm: '8px',
                md: '8px',
                lg: '8px',
                xl: '7px',
              }}
              // bottom=""
              width={{ base: '83px', md: '153px', xl: '180px' }}
              height={{ base: '122.24px', md: '200.24px', xl: '243.22px' }}
              zIndex="5"
            >
              <LazyLoadImageComponent
                src={`${config.imgPath}/d/46272/${bgBlock[blockStyle]}`}
                objectFit={{ base: 'cover', md: 'contain' }}
                width="100%"
                height="100%"
              />
              <Text
                color="title.dark"
                fontSize={{ base: '28px', md: 'h5' }}
                fontFamily="fedraSerifDisplay"
                fontWeight={600}
                as="h3"
                lineHeight={{ base: '30px', md: '49.22px' }}
                position="absolute"
                top={{ base: '50px', md: '90px', xl: '110px' }}
                left={{ base: '80px', md: '175px', xl: '180px' }}
                w="max-content"
                mb={
                  blockStyle === 'tree-decoration' ||
                  blockStyle === 'triangle-decoration'
                    ? '12px'
                    : '0'
                }
              >
                {sectionContent?.title}
              </Text>
            </Box>
          )}
          {post?.map((blog, index) => {
            if (index < (tab?.numberOfPosts || post.length)) {
              return blogCardStyle === 'without_image' ? (
                <PostWImage
                  post={blog}
                  region={region}
                  lang={lang}
                  key={nanoid()}
                  // mx={{ base: '10px', md: '19px' }}
                  // my={{ base: '10px', md: '15px' }}
                />
              ) : (
                <Post
                  key={nanoid()}
                  post={blog}
                  lang={lang}
                  region={region}
                  // mx={{ base: '10px', md: '19px' }}
                  // my={{ base: '10px', md: '15px' }}
                />
              );
            }
          })}
        </Flex>
        {!post.length && !loading && (
          <Box ml="70px" py="30px">
            Sorry, No results found.
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BlogCard;
